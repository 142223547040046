import { useQuery } from '@tanstack/react-query';
import i18next from 'i18next';
import qs from 'qs';
import publicClient from '../../lib/publicClient';
import { toGuideDetails, toTripDetails } from '../Db/utils';
import { GuideDetails, TripDetails } from '../Guide/guide';
import storage from '../../utils/storage';
import { currentApi } from '../Db/dbInfo';
import { preloadImage } from '../utils';
import { isArray, isString } from 'lodash';

const queryKey = 'search';
export interface ISearchDataOptions {
  noOfPassenger?: number;
  city?: string;
  countryCode?: string;
  search?: string;
  targetedSpecies?: number[];
  fishingTechniques?: number[];
  fishingTypes?: number[];
  sortBy?: string;
}

export function searchTripQueryParam(props: ISearchDataOptions): string {
  const {
    city,
    search,
    targetedSpecies,
    fishingTechniques,
    fishingTypes,
    noOfPassenger = 1,
    countryCode,
    sortBy,
  } = props;
  storage.setNoOfPassenger(noOfPassenger);
  let queryParam: any = {
    filters: {
      guide: { id: { $notNull: true }, status: 'accepted' },
      $or: [
        { maxAmountofPeople: { $gte: noOfPassenger } },
        {
          guide: {
            paying_member: { $notNull: true },
          },
        },
      ],
    },
    fields: ['name', 'long', 'lat', 'type', 'slug'],
    sort: ['guide.paying_member:DESC', 'guide.ranking:DESC'],
    populate: {
      rating: { field: ['average'] },
      tripMedia: { fields: ['url'] },
      targetedSpecies: { fields: ['name'] },
      fishingTechniques: { fields: ['name'] },
      fishingTypes: { fields: ['name'] },
      guide: { fields: ['id', 'ranking', 'firstName', 'lastName', 'paying_member'] },
    },
  };

  if (sortBy && sortBy === 'rating') {
    queryParam.sort = ['rating.average:DESC'];
  }

  if (city) {
    queryParam.filters.city = {
      $eq: `${city}`,
    };
  }

  if (countryCode) {
    queryParam.filters.country = {
      $eq: `${countryCode}`,
    };
  }

  if (search) {
    queryParam.filters.name = {
      $contains: search,
    };
  }

  if (targetedSpecies && targetedSpecies.length > 0) {
    queryParam.filters.targetedSpecies = {
      id: {
        $in: targetedSpecies,
      },
    };
  }

  if (fishingTechniques && fishingTechniques.length > 0) {
    queryParam.filters.fishingTechniques = {
      id: {
        $in: fishingTechniques,
      },
    };
  }

  if (fishingTypes && fishingTypes.length > 0) {
    queryParam.filters.fishingTypes = {
      id: {
        $in: fishingTypes,
      },
    };
  }

  return qs.stringify(queryParam, { encode: false });
}

export function sortByQueryParam(sortBy: string, queryParamString: string): string {
  const queryParam = qs.parse(queryParamString);

  if (sortBy && sortBy === 'rating') {
    queryParam.sort = ['rating.average:DESC'];
  } else {
    queryParam.sort = ['guide.ranking:DESC'];
  }
  return qs.stringify(queryParam, { encode: false });
}

export function nearBySearchTripQueryParam(props: ISearchDataOptions): string {
  const {
    city,
    search,
    targetedSpecies,
    fishingTechniques,
    fishingTypes,
    noOfPassenger = 1,
    countryCode,
  } = props;

  let queryParam: any = {
    filters: {
      guide: { id: { $notNull: true }, status: 'accepted' },
      $or: [
        { maxAmountofPeople: { $gte: noOfPassenger } },
        {
          guide: {
            paying_member: { $notNull: true },
          },
        },
      ],
    },
    fields: ['name', 'city', 'country', 'slug'],
    populate: {
      tripMedia: { fields: ['url'] },
      fishingTechniques: { fields: ['name'] },
      fishingTypes: { fields: ['name'] },
      guide: { fields: ['id'] },
    },
  };

  if (city) {
    queryParam.filters.city = {
      $eq: `${city}`,
    };
  }

  if (countryCode) {
    queryParam.filters.country = {
      $eq: `${countryCode}`,
    };
  }

  if (search) {
    queryParam.filters.name = {
      $contains: search,
    };
  }

  if (targetedSpecies && targetedSpecies.length > 0) {
    queryParam.filters.targetedSpecies = {
      id: {
        $in: targetedSpecies,
      },
    };
  }

  if (fishingTechniques && fishingTechniques.length > 0) {
    queryParam.filters.fishingTechniques = {
      id: {
        $in: fishingTechniques,
      },
    };
  }

  if (fishingTypes && fishingTypes.length > 0) {
    queryParam.filters.fishingTypes = {
      id: {
        $in: fishingTypes,
      },
    };
  }

  return qs.stringify(queryParam, { encode: false });
}

export const useSearchTrips = (queryParams: string) => {
  return useQuery<TripDetails[]>([queryKey, i18next.language, queryParams], async () => {
    return await publicClient
      .get(`/guidetrips?locale=${i18next.language}&${queryParams}`)
      .then(function (response: any) {
        const data = response.data;
        const trips = data?.data?.map((trip: TripDto) => {
          return toTripDetails(trip);
        });
        return trips?.filter((trip: TripDetails) => trip.guide);
      });
  });
};

export const useSearchTripsByDistance = () => {
  return useQuery<TripDetails[]>([queryKey, i18next.language], async () => {
    return await publicClient
      .get(`/guidetrips/findTripBasedOnDistance/49/49/23?locale=${i18next.language}`)
      .then(function (response: any) {
        const data = response.data;
        const trips = data?.data?.map((trip: TripDto) => {
          return toTripDetails(trip);
        });

        return trips;
      });
  });
};

export const useGetGuideById = (id: number) => {
  return useQuery<GuideDetails>(
    [i18next.language, queryKey, 'guide', id],
    async () => {
      const queryParams = qs.stringify(
        {
          publicationState: 'live',
          populate: {
            businessMedia: {
              fields: ['id', 'name', 'url'],
            },
            profilePhoto: {
              fields: ['id', 'name', 'url'],
            },
            title: { fields: ['id', 'name'] },
          },
        },
        { encode: false }
      );

      return await publicClient
        .get(`/guides/${id}?locale=${i18next.language}&${queryParams}`)
        .then(function (response: any) {
          return toGuideDetails(response.data?.data);
        });
    },
    { keepPreviousData: true }
  );
};

export const useGetGuideMembershipById = (id: number) => {
  return useQuery<{ paying_member: any | null; website: string | null }>(
    [i18next.language, queryKey, 'guide-membership', id],
    async () => {
      const queryParams = qs.stringify(
        {
          publicationState: 'live',
          fields: ['paying_member', 'website'],
        },
        { encode: false }
      );

      return await publicClient
        .get(`/guides/${id}?locale=${i18next.language}&${queryParams}`)
        .then(function (response: any) {
          return {
            paying_member: response.data?.data?.paying_member ?? null,
            website: response.data?.data?.website ?? null,
          };
        });
    },
    { keepPreviousData: true }
  );
};

export const useGetAverageResponceTimeByGuideId = (id: number) => {
  return useQuery<any>(
    [queryKey, 'AverageResponceTime', id],
    async () => {
      return await publicClient
        .get(`/conversation/getAverageResponseTime/${id}`)
        .then(function (response: any) {
          return response.data;
        });
    },
    { keepPreviousData: true }
  );
};

export const useGetTripById = (id: number) => {
  return useQuery<TripDetails>(
    [i18next.language, queryKey, id],
    async () => {
      const queryParams = qs.stringify(
        {
          publicationState: 'live',
          populate: {
            tripMedia: { fields: ['id', 'name', 'url'] },
            targetedSpecies: { fields: ['id', 'name'] },
            fishingTechniques: { fields: ['id', 'name'] },
            fishingTypes: { fields: ['id', 'name'] },
            catchPolicy: { fields: ['id', 'name'] },
            foodAndBeverages: { fields: ['id', 'name'] },
            cancellationPolicy: { fields: ['id', 'name'] },
            pickupPolicy: { fields: ['id', 'name'] },
            guide_boat: {
              populate: {
                boatMedia: { fields: ['id', 'name', 'url'] },
                typeofEngine: { fields: ['id', 'name'] },
                boatType: { fields: ['id', 'name'] },
                navEquipments: { fields: ['id', 'name'] },
                safetyEquipments: { fields: ['id', 'name'] },
                facilities: { fields: ['id', 'name'] },
                fishingGears: { fields: ['id', 'name'] },
                additionalCrew: { fields: ['id', 'name'] },
              },
            },
            guide: {
              fields: ['id', 'businessName', 'showTempMedia'],
              populate: {
                businessMedia: {
                  fields: ['id', 'name', 'url'],
                },
                profilePhoto: {
                  fields: ['id', 'name', 'url'],
                },
              },
            },
          },
        },
        { encode: false }
      );

      return await publicClient
        .get(`/guidetrips/${id}?locale=${i18next.language}&${queryParams}`)
        .then(function (response: any) {
          const tripnfo = toTripDetails(response.data?.data);
          tripnfo.tripMedia?.forEach((media) => {
            if (media && media.url) {
              preloadImage(media.url).catch((e) => {
                // console.log("error", e);
              });
            }
          });
          return tripnfo;
        });
    },
    { keepPreviousData: true }
  );
};

export const useGetTripByIdOrSlug = (id: number | string) => {
  return useQuery<TripDetails>(
    [i18next.language, queryKey, id],
    async () => {
      const filters = isString(id)
        ? {
            slug: {
              $eq: id,
            },
          }
        : {};
      const queryParams = qs.stringify(
        {
          publicationState: 'live',
          populate: {
            tripMedia: { fields: ['id', 'name', 'url'] },
            targetedSpecies: { fields: ['id', 'name'] },
            fishingTechniques: { fields: ['id', 'name'] },
            fishingTypes: { fields: ['id', 'name'] },
            catchPolicy: { fields: ['id', 'name'] },
            foodAndBeverages: { fields: ['id', 'name'] },
            cancellationPolicy: { fields: ['id', 'name'] },
            pickupPolicy: { fields: ['id', 'name'] },
            guide_boat: {
              populate: {
                boatMedia: { fields: ['id', 'name', 'url'] },
                typeofEngine: { fields: ['id', 'name'] },
                boatType: { fields: ['id', 'name'] },
                navEquipments: { fields: ['id', 'name'] },
                safetyEquipments: { fields: ['id', 'name'] },
                facilities: { fields: ['id', 'name'] },
                fishingGears: { fields: ['id', 'name'] },
                additionalCrew: { fields: ['id', 'name'] },
              },
            },
            guide: {
              fields: ['id', 'businessName', 'showTempMedia'],
              populate: {
                businessMedia: {
                  fields: ['id', 'name', 'url'],
                },
                profilePhoto: {
                  fields: ['id', 'name', 'url'],
                },
              },
            },
          },
          filters,
        },
        { encode: false }
      );

      return await publicClient
        .get(`/guidetrips/${isString(id) ? '' : id}?locale=${i18next.language}&${queryParams}`)
        .then(function (response: any) {
          
          const tripnfo = toTripDetails(isArray(response.data?.data) ? response.data?.data[0] : response.data?.data);
          tripnfo.tripMedia?.forEach((media) => {
            if (media && media.url) {
              preloadImage(media.url).catch((e) => {
                // console.log("error", e);
              });
            }
          });
          return tripnfo;
        });
    },
    { keepPreviousData: true }
  );
};

export const useGetAvailableTripCountry = () => {
  return useQuery<any>(
    ['GetAvailableTripCountry'],
    async () => {
      return await publicClient.get(`/getAvailableTripCountry`).then(function (response: any) {
        return response.data;
      });
    },
    { keepPreviousData: true }
  );
};

export const useGetTempCoverPic = () => {
  return useQuery<any>(
    ['GetTempCoverPic'],
    async () => {
      return await publicClient
        .get(`/design-config?fields=id&populate=tempCoverPicture`)
        .then(function (response: any) {
          if (response.data && response.data.data && response.data.tempCoverPicture?.url) {
            preloadImage(`${currentApi.fileBasePath + response.data.tempCoverPicture?.url}`);
          }
          return response.data;
        });
    },
    { keepPreviousData: true }
  );
};
